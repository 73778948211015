import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ELSODateTimePickerComponent } from '../customComponents/elso-datetimepicker/elso-datetimepicker.component';
import { AngularMaterialModule } from './angular-material.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule, DateTimeAdapter } from 'ng-pick-datetime';
import { CustomOwlDateTimeAdapter } from '../classes/owlCustomDateAdapterr';
import { ELSOInputComponent } from '../customComponents/elso-input/elso-input.component';
import { ValidateMinMaxNumberDirective } from '../directives/validate-minmax-number.directive';
import { MdePopoverModule } from '@material-extended/mde';
import { ValidateDateDirective } from '../directives/validate-date.directive';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { ELSOFilterPipe } from '../pipes/elso-grid-filter.pipe';
import { PaginatorService } from '../services/paginator.service';
import { UnitValueConversionPipe } from '../pipes/univ-value-conversion.pipe';
import { TooltipDirective } from '../directives/tooltip.directive';
import { MinMaxTooltipComponent, TooltipContainerDirective } from '../components/tooltip/tooltip.component';
import { ValidateNumberPrecisionDirective } from '../directives/validate-number-precision.directive';
import { UnitValueConversionDirective } from '../directives/unit-value-conversion.directive';

@NgModule({
  imports: [
    AngularMaterialModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MdePopoverModule
  ],
  declarations: [
    ELSODateTimePickerComponent,
    ELSOInputComponent,
    ValidateMinMaxNumberDirective,
    ValidateDateDirective,
    DateFormatPipe,
    ELSOFilterPipe,
    UnitValueConversionPipe,

    TooltipDirective,
    TooltipContainerDirective,
    MinMaxTooltipComponent,
    UnitValueConversionDirective,
    ValidateNumberPrecisionDirective,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ELSODateTimePickerComponent,
    ELSOInputComponent,
    ValidateMinMaxNumberDirective,
    ValidateDateDirective,
    DateFormatPipe,
    ELSOFilterPipe,
    UnitValueConversionPipe,
    TooltipDirective,
    TooltipContainerDirective,
    MinMaxTooltipComponent,
    UnitValueConversionDirective,
    ValidateNumberPrecisionDirective,
  ],
  providers: [
    CustomOwlDateTimeAdapter, // so we could inject services to 'CustomDateAdapter'
    { provide: DateTimeAdapter, useClass: CustomOwlDateTimeAdapter }, // Parse MatDatePicker Format
    PaginatorService
  ]
})
export class SharedModule{


}
