import { Directive, ElementRef, HostListener, Input, } from '@angular/core';
import { Form, FormGroup, NgControl, NgModel } from '@angular/forms';
import { AppService } from '../services/app.service';
import { MinMaxValidationService } from '../services/minmax-validation.service';

@Directive({
  selector: '[unitValueConversion]'
})
export class UnitValueConversionDirective {

  @Input() public unitValueConversion: string;


  constructor(private appService: AppService, private ngControl: NgControl) {
    
  }

  ngOnInit() {
    //console.log(this.ngControl.control.value);
    this.ngControl.control.setValue(this.convertValue());
    //console.log(this.ngControl.control.value);
  }

  convertValue() {
    var isUS = false;
    if (this.appService.UserProfile.UnitSystem == 1) {
      isUS = true;
    }

    var value = this.ngControl.control.value;

    if (isUS) {//if system is US, return same value because us value is stored
      return value;
    } else {//if system is SI, convert value
      var floatValueToUse = 0;
      var decimalPlaces = 0;
      var divideOrMultiply = '';
      switch (this.unitValueConversion) {
        case 'mgdl-umolL-bilirubin':
          floatValueToUse = 0.058479532;
          decimalPlaces = 3;
          divideOrMultiply = 'divide';
          break;
        case 'kPa-mmHg':
          floatValueToUse = 7.5;
          decimalPlaces = 3;
          divideOrMultiply = 'divide';
          break;
        case 'kPa-mmHg2Decimals':
          floatValueToUse = 7.5;
          decimalPlaces = 2
          divideOrMultiply = 'divide';
          break;
        case 'kPa-g/L-g/dl':
          floatValueToUse = 0.1;
          decimalPlaces = 2;
          divideOrMultiply = 'divide';
          break;
        case 'kPa-mmol/L-mg/dL-Ca':
          floatValueToUse = 4;
          decimalPlaces = 2;
          divideOrMultiply = 'divide';
          break;
        case 'μmol/L-mg/dL-Creatinine':
          floatValueToUse = 0.058479532;
          decimalPlaces = 3;
          divideOrMultiply = 'divide';
          break;
        case 'μmol/L-mg/dL-Bilirubin':
          floatValueToUse = 0.058479532;
          decimalPlaces = 3;
          divideOrMultiply = 'divide';
          break;
        case 'μkat/L-U/L-AST':
          floatValueToUse = 60.24096386;
          decimalPlaces = 2;
          divideOrMultiply = 'divide';
          break;
        case 'g/L-mg/dL-Fibr':
          floatValueToUse = 100;
          decimalPlaces = 2;
          divideOrMultiply = 'divide';
          break;
        case 'mg/L-nmol/L-CRP':
          floatValueToUse = 9.524;
          decimalPlaces = 2;
          divideOrMultiply = 'multiply';
          break;
        default:
      }

      if (divideOrMultiply == 'divide') {
        return parseFloat((parseFloat(value) / floatValueToUse).toFixed(decimalPlaces));
      } else {
        return parseFloat((parseFloat(value) * floatValueToUse).toFixed(decimalPlaces));
      }
    }
  }

}
