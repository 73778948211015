import { Component, Input } from '@angular/core';
import { AppService } from '../../services/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { EnumService } from '../../services/enum.service';

@Component({
  templateUrl: './settings.component.html',
})

export class SettingsComponent {

  constructor(private ls: LoadingService, private dataService: DataService, private appService: AppService, private _dateTimeService: DateTimeFormatService
    , private fb: FormBuilder, private router: Router, private enumService: EnumService) { }

  form!: FormGroup;
  formAdministration!: FormGroup;
  centers = [];
  showCentersList: boolean = false;
  systemVersion: any;

  public onSelectionChange_Format(option): void {
    //console.log(moment.localeData(window.navigator.language).longDateFormat('l'));
    //var dateFormatFromLocale = moment.localeData(window.navigator.language).longDateFormat('L');

    //if (option == 1) {//default culture 12h format
    //  this._dateTimeService.locale = window.navigator.language;
    //  this._dateTimeService.twelveHourFormat = true;
    //  dateFormatFromLocale = dateFormatFromLocale + ' hh:mm a';
    //} else if (option == 2) {//default culture 24h format
    //  this._dateTimeService.locale = window.navigator.language;
    //  this._dateTimeService.twelveHourFormat = false;
    //  dateFormatFromLocale = dateFormatFromLocale + ' HH:mm';
    //} else if (option == 3) {//en-US 12h format
    //  this._dateTimeService.locale = 'en-US';
    //  this._dateTimeService.twelveHourFormat = true;
    //  dateFormatFromLocale = 'MMMM D YYYY hh:mm a';
    //} else if (option == 4) {//en-US 24h format
    //  this._dateTimeService.locale = 'en-US';
    //  this._dateTimeService.twelveHourFormat = false;
    //  dateFormatFromLocale = 'MMMM D YYYY HH:mm';
    //}

    //this._dateTimeService.format = dateFormatFromLocale;

    //var cultureTokenString = this._dateTimeService.locale + '|' + this._dateTimeService.format + '|' + this._dateTimeService.twelveHourFormat;
    //console.log(cultureTokenString);
    //localStorage.setItem('cultureSettings', cultureTokenString);
  }

  save() {

    //console.log(this.form.value);

    var dateFormatFromLocale = moment.localeData(window.navigator.language).longDateFormat('l');//.longDateFormat('L');
    //set day and year to lowercase.
    //dateFormatFromLocale = dateFormatFromLocale.replace(/D/g, "d"); //moment js doesnt work well when day mark is small letter d
    //dateFormatFromLocale = dateFormatFromLocale.replace(/Y/g, "y"); //moment js doesnt work well when year mark is small letter y

    //console.log(dateFormatFromLocale);
    //console.log(moment.localeData(window.navigator.language).longDateFormat('LL'));

    if (this.form.controls['CultureOptionSelected'].value == 1) {//default culture 12h format
      this._dateTimeService.locale = window.navigator.language;
      this._dateTimeService.twelveHourFormat = true;
      dateFormatFromLocale = dateFormatFromLocale + ' hh:mm a';//+ ' hh:mm a';
    } else if (this.form.controls['CultureOptionSelected'].value == 2) {//default culture 24h format
      this._dateTimeService.locale = window.navigator.language;
      this._dateTimeService.twelveHourFormat = false;
      dateFormatFromLocale = dateFormatFromLocale + ' HH:mm';
    } else if (this.form.controls['CultureOptionSelected'].value == 3) {//en-US 12h format
      this._dateTimeService.locale = 'en-US';
      this._dateTimeService.twelveHourFormat = true;
      dateFormatFromLocale = 'M/D/YYYY hh:mm a';//dateFormatFromLocale = 'MMMM D YYYY hh:mm a';
    } else if (this.form.controls['CultureOptionSelected'].value == 4) {//en-US 24h format
      this._dateTimeService.locale = 'en-US';
      this._dateTimeService.twelveHourFormat = false;
      dateFormatFromLocale = 'M/D/YYYY HH:mm';//dateFormatFromLocale = 'MMMM D YYYY HH:mm';
    }

    this._dateTimeService.format = dateFormatFromLocale;

    var userProfile = {
      DateTimeFormat: this._dateTimeService.locale + '|' + this._dateTimeService.format + '|' + this._dateTimeService.twelveHourFormat,
      UnitSystem: this.form.controls['UnitSystem'].value,
      CultureOptionSelected: this.form.controls['CultureOptionSelected'].value,
      CenterSelected: this.form.controls['CenterSelected'].value
    }

    localStorage.setItem('unitSystem', this.form.controls['UnitSystem'].value);
    localStorage.setItem('cultureSettings', this._dateTimeService.locale + '|' + this._dateTimeService.format + '|' + this._dateTimeService.twelveHourFormat);
    localStorage.setItem('cultureOptionSelected', this.form.controls['CultureOptionSelected'].value);

    this.dataService.update('Users/UpdateUserProfile', userProfile).subscribe(
      (res: any) => {
        this.appService.setUserProfile();

        this.router.navigateByUrl('/home')
      },
      err => {
        console.log(err);
      }
    )
  }


  updateAdministration() {
    //console.log(this.formAdministration.value);
    this.dataService.update('Settings/UpdateAdministration', this.formAdministration.value).subscribe(
      (res: any) => {
        this.router.navigateByUrl('/home')
      },
      err => {
        console.log(err);
      }
    )
  }

  ngOnInit() {
    //console.log(this.appService.UserProfile);
    this.form = this.fb.group({
      UnitSystem: new FormControl(parseInt(this.appService.UserProfile.UnitSystem), Validators.required),
      CultureOptionSelected: new FormControl(parseInt(this.appService.UserProfile.CultureOptionSelected), Validators.required),
      CenterSelected: new FormControl(this.appService.UserProfile.CenterId)
    });

    this.formAdministration = this.fb.group({
      SystemMessage: new FormControl(''),
      IsOffline: new FormControl(false, Validators.required)
    });

    if (this.appService.isUserInRole('Host User') || this.appService.isUserInRole('Host User - Registry Only')) {

      this.dataService.getall('Settings/GetSystemVersion').subscribe(
        (res: any) => {
          //console.log(res);
          this.systemVersion = res.data;
          this.formAdministration = this.fb.group({
            SystemMessage: new FormControl(this.systemVersion.SystemMessage),
            IsOffline: new FormControl(this.systemVersion.IsOffline, Validators.required)
          });
        },
        err => {
          console.log(err);
        }
      );
      
    }

    
    //$scope.SystemOnlineOfflineValue = appService.findBy($rootScope.enumSystemOnlineOffline, 'value', $rootScope.loggedUser.IsOffline).value;

    //if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true || this.appService.isUserInRole('Multi Center User') == true) {
    //  //get centers for user, show dd list
    //  this.dataService.getall('Users/GetCentersForUser').subscribe(
    //    (res: any) => {
    //      this.centers = res.data;
    //      this.showCentersList = true;
    //    },
    //    err => {
    //      console.log(err);
    //    }
    //  );
    //}
    
  }
}
