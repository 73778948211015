import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Inject, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { AppService } from '../../services/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceScannerComponent } from '../../customComponents/deviceScanner/deviceScanner.component';
import { EventEmitter } from '@angular/core';

@Component({
  templateUrl: './scanDevice.component.html',
})

export class ScanDeviceComponent {

  private optionSelected: number;
  private buttonLabel: string;
  form!: FormGroup;
  scannedDeviceGroups: [];
  constructor(private dataService: DataService, private appService: AppService, private _dateTimeService: DateTimeFormatService, private fb: FormBuilder
    , private router: Router, public dialog: MatDialog) {

  }

  loadFields(optionSelected) {
    //this.setModal(null);
    this.form = this.fb.group({});
    switch (optionSelected) {
      case 'patientOnECMO':
        this.buttonLabel = 'Find patient and proceed to scan'
        this.optionSelected = 1;
        this.form.addControl('ScanOption', new FormControl(this.optionSelected));
        this.form.addControl('Sex', new FormControl(null, Validators.required));
        this.form.addControl('BirthDate', new FormControl(null, Validators.required));
        //this.form.addControl('DeviceScanId', new FormControl(1));
        //this.form.addControl('PatientUniqueId', new FormControl(''));
        //this.form.addControl('TimeOn', new FormControl(null));
        //this.form.addControl('TimeOff', new FormControl(null));
        //this.form.addControl('DeviceScanSetName', new FormControl(''));
        //this.form.addControl('ScanDateTime', new FormControl(null));
        //this.form.addControl('ScanOption', new FormControl(1));
        //this.form.addControl('CenterId', new FormControl(null));
        //this.form.addControl('UserId', new FormControl(null));
        //this.form.addControl('DeviceGudidId', new FormControl(''));
        //this.form.addControl('DeviceType', new FormControl(1));
        //this.form.addControl('DeviceName', new FormControl(''));
        //this.form.addControl('DeviceManufacturer', new FormControl(''));
        //this.form.addControl('DeviceModelNumber', new FormControl(''));
        break;
      case 'patientWasOnECMO':
        this.buttonLabel = 'Find patient and proceed to scan'
        this.optionSelected = 2;
        this.form.addControl('ScanOption', new FormControl(this.optionSelected));
        this.form.addControl('Sex', new FormControl(null, Validators.required));
        this.form.addControl('BirthDate', new FormControl(null, Validators.required));
        this.form.addControl('TimeOn', new FormControl(null, Validators.required));
        this.form.addControl('TimeOff', new FormControl(null, Validators.required));
        this.form.addControl('PatientUniqueId', new FormControl(null, Validators.required));
        break;
      case 'deviceFutureUse':
        this.buttonLabel = 'Proceed to scan'
        this.optionSelected = 3;
        this.form.addControl('ScanOption', new FormControl(this.optionSelected));
        this.form.addControl('DeviceScanSetName', new FormControl(null, Validators.required));
        break;
    }
  }

  openScanner() {
    if (this.optionSelected != 3) {
      //find patient
      console.log(this.form.getRawValue());
      this.dataService.update('DeviceScan/FindPatient', this.form.getRawValue()).subscribe(
        (res: any) => {
          console.log(res.data);

          if (res.data != null) {
            //proceed to scanner
            this.setModal(res.data);
          } else {
            alert('No patient mathching filter.')
          }

        },
        err => {
          console.log(err);
        }
      )
    } else {
      //proceed to scanner
      this.setModal(null);
    }
  }

  setModal(patients: any) {
    let dialogRef = this.dialog.open(ScanDeviceDialog, {
      panelClass: 'modal-scanner-template', // class to style your dialog
      data: {
        patients: patients,
        deviceScanSetName: this.form.controls['DeviceScanSetName'] != undefined ? this.form.controls['DeviceScanSetName'].value : null,
        scanOptionSelected: this.form.controls['ScanOption'].value,
        scannedDeviceGroups: this.scannedDeviceGroups,
        title: 'Device scaner',
        component: DeviceScannerComponent, //--> here component to load
        //inputs: { // here an object with inputs data needed by your hosted component
        //  text: 'some text'
        //}
      },
      width: '800px',
      height: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.dialogOption == 'yes') {
          if (result.scanOptionSelected == 3) {
            this.getPreScannedDevices();
          }
        }
      }
    });
  }

  ngOnInit() {
    this.getPreScannedDevices();
  }

  getPreScannedDevices() {
    this.dataService.getall('DeviceScan/GetScannedDeviceGroups').subscribe(
      (res: any) => {
        this.scannedDeviceGroups = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }
  //setModal(scanFilter: any) {
  //  const dialogRef = this.dialog.open(ScanDeviceDialog, {
  //    width: '700px',
  //    data: { scanFilter: scanFilter }
  //  });

  //  dialogRef.afterClosed().subscribe(result => {

  //    if (result) {
  //      if (result.dialogOption == 'yes') {
          

  //      }
  //    }

  //  });
  //}
}



@Component({
  selector: 'dialog-scandevice',
  templateUrl: 'scanDeviceDialog.html',
})
export class ScanDeviceDialog {

  @ViewChild('dynamicComponent', { read: ViewContainerRef }) container;
  componentRef: ComponentRef<any>
  hostedComponent = null;
  form!: FormGroup;
  filteredPatients: any;
  hostedComponentSubs: any;
  scannedDevices = [];
  selectedPatient = null;
  selectedRun = null;
  scanOptionSelected = null;
  deviceScanSetName = null;
  scannedDeviceGroups = [];

  constructor(public dialogRef: MatDialogRef<ScanDeviceDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private appService: AppService, private fb: FormBuilder, private dataService: DataService
    , private resolver: ComponentFactoryResolver) {
  }


  ngOnInit() {
    this.form = this.fb.group({});
    console.log(this.data.patients);
    this.filteredPatients = this.data.patients;
    this.scanOptionSelected = this.data.scanOptionSelected;
    this.deviceScanSetName = this.data.deviceScanSetName;
    this.scannedDeviceGroups = this.data.scannedDeviceGroups;
    console.log(this.scannedDeviceGroups);

    if (this.scanOptionSelected != 3) {
      this.form.addControl('RunSelected', new FormControl(null, Validators.required));
    }

    //this.form.addControl('DeviceType', new FormControl(null, Validators.required));
    if (this.scannedDeviceGroups.length > 0 && this.scanOptionSelected != 3) {
      this.form.addControl('PreScannedDevice', new FormControl(null));
      this.form.addControl('DeviceType', new FormControl(null));
      console.log('aaaa');
    } else {
      //DeviceType is required when there are no pre scanned devices
      this.form.addControl('DeviceType', new FormControl(null, Validators.required));
    }



    setTimeout(() => {//Timeout is required for container to be defined
      this.createComponent();
    }, 0);
    
  }

  confirmDialogOption(dialogOption: string): void {
    var dialogResult = {
      strNextOrBack: this.data.strNextOrBack,
      dialogOption: dialogOption,
      data: this.form.value,
      scanOptionSelected: this.scanOptionSelected
      // createEdit: this.data.createEdit
    };

    if (dialogOption == 'yes') {
      if (this.scannedDevices.length > 0) {
        if (this.scanOptionSelected != 3) {
          this.dataService.create('DeviceScan/CreateScannedDevicesForPatient', this.scannedDevices).subscribe(
            (res: any) => {
              this.dialogRef.close(dialogResult);
            },
            err => {
              console.log(err);
            }
          );

        } else {
          this.dataService.create('DeviceScan/CreateScannedDevices', this.scannedDevices).subscribe(
            (res: any) => {
              this.dialogRef.close(dialogResult);
            },
            err => {
              console.log(err);
            }
          );
        }
      }
    } else {
      this.dialogRef.close(dialogResult);
    }
  }

  createComponent() {
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.container.createComponent(factory);
    //const hostedComponent = this.componentRef.instance;
    this.hostedComponent = this.componentRef.instance;

    //hostedComponent.bShowScanner = false;
    //hostedComponent.bShowImgDecode = false;

    // pass Inputs to component
  ////if (this.data.inputs) {
  ////  Object.keys(this.data.inputs).forEach(inputName => {
  ////    hostedComponent[inputName] = this.data.inputs[inputName]
  ////  })
  ////}

    //////////////// Pass through outputs from hosted component//////////////////
    Object.keys(this.hostedComponent)
      .filter(prop => this.hostedComponent[prop] instanceof EventEmitter)
      .forEach(output => {
        this[output] = new EventEmitter()
        this[`${output}_sub`] = this.hostedComponent[output].subscribe(data => {
          this[output].emit(data)
        })
      });
    //used to receive data from child component that is hosted in material dialog/modal. This wont work without code above
    this.dialogRef.afterOpened()
      .subscribe(() => {
        this.hostedComponentSubs = this.dialogRef.componentInstance['barCodeScanned'].subscribe((event) => { this.attachScannedDevice(event); });
      });
    ////////////////

    //unsubscribe everything after dialog is closed
    this.dialogRef.afterClosed().subscribe(() => this.hostedComponentSubs.unsubscribe())
  }

  attachScannedDevice(scannedDevice, preScanned?) {
    //console.log(scannedDevice);
    if (this.scannedDevices.length > 0) {
      if (this.appService.lodashFindBy(this.scannedDevices, 'DeviceGudidId', scannedDevice.scannedCode)) {
        var confirmed = confirm('Scanned device is already added. Add another device with same code?');
        if (confirmed != true) {
          return;
        }
      }
    }
    this.scannedDevices.push(
      {
        TempId: this.appService.generateUUID(),
        DeviceScanId: 0,
        PatientUniqueId: this.selectedPatient != null ? this.selectedPatient.UniqueId : null,
        BirthDate: this.selectedPatient != null ? this.selectedPatient.Birthdate : null,
        TimeOn: this.selectedRun != null ? this.selectedRun.TimeOn : null,
        TimeOff: this.selectedRun != null ? this.selectedRun.TimeOff : null,
        Sex: this.selectedPatient != null ? this.selectedPatient.Sex : null,
        DeviceScanSetName: this.deviceScanSetName,        
        ScanOption: this.scanOptionSelected,
        DeviceGudidId: scannedDevice.scannedCode,
        DeviceType: preScanned == true ? scannedDevice.deviceType : this.form.controls['DeviceType'].value,
        //ScanDateTime: null,
        //CenterId: null,
        //UserId: null,
        //DeviceName: '',
        //DeviceManufacturer: '',
        //DeviceModelNumber: '',
      }
    );
    console.log(this.scannedDevices);
  }

  deviceTypeSelectionChange(value) {
    if ((this.scanOptionSelected != 3 && this.form.controls['RunSelected'].value == null) || this.form.controls['DeviceType'].value == null) {
      this.hostedComponent.disabled = true; //disable scanner button
    } else {
      this.hostedComponent.disabled = false; //enable scanner button
    }
  }

  runIdSelectionChange(patient, run) {
    this.selectedPatient = null;
    this.selectedRun = null;

    if (run) {
      this.form.controls['RunSelected'].setValue(run.RunId);
      this.form.controls['RunSelected'].updateValueAndValidity();
    }

    if (this.filteredPatients.length > 1) {//There are more than one patient, remove other patients
      var confirm = confirm("By selecting this patient run, other patients will be removed from filter. Please confirm if devices will be scanned for this patient runs.");

      if (confirm != true) {
        //reset RunSelected value
        this.form.controls['RunSelected'].setValue(null);
        this.form.controls['RunSelected'].updateValueAndValidity();
        return;
      }
    }

    this.selectedPatient = patient;
    this.selectedRun = run;

    if ((this.scanOptionSelected != 3 && this.form.controls['RunSelected'].value == null) || this.form.controls['DeviceType'].value == null) {
      this.hostedComponent.disabled = true; //disable scanner button
    } else {
      this.hostedComponent.disabled = false; //enable scanner button
    }
  }

  deleteScannedDevice(scannedDevice) {
    this.appService.lodashRemoveBy(this.scannedDevices, 'TempId', scannedDevice.TempId);
  }
  addPreScannedDevice() {
    console.log(this.appService.lodashFindBy(this.scannedDeviceGroups, 'DeviceGudidId', this.form.controls['PreScannedDevice'].value));
    this.attachScannedDevice({ scannedCode: this.form.controls['PreScannedDevice'].value, deviceType: this.appService.lodashFindBy(this.scannedDeviceGroups, 'DeviceGudidId', this.form.controls['PreScannedDevice'].value).DeviceType }, true);
  }
}

