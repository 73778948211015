import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Inject, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { AppService } from '../../services/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceScannerComponent } from '../../customComponents/deviceScanner/deviceScanner.component';
import { PaginatorService } from '../../services/paginator.service';
import { EventEmitter } from '@angular/core';

@Component({
  templateUrl: './patientsWithSandDevices.component.html',
})

export class PatientsWithSandDevicesComponent {

  private optionSelected: number;
  private buttonLabel: string;
  form!: FormGroup;
  scannedDeviceGroups: [];
  patientsWithScannedDevices: [];
  constructor(private dataService: DataService, private appService: AppService, private _dateTimeService: DateTimeFormatService, private fb: FormBuilder
    , private router: Router, public dialog: MatDialog, private paginatorService: PaginatorService) {

  }

  displayPatientDevices(patient: any) {
    let dialogRef = this.dialog.open(ScanDevicePatientDialog, {
      panelClass: 'modal-scanner-template', // class to style your dialog
      data: {
        patient: patient,
        scannedDeviceGroups: this.scannedDeviceGroups, //pre scanned devices
        title: 'Device scaner',
        component: DeviceScannerComponent, //--> here component to load
      },
      width: '800px',
      height: '700px'
    });
  }

  ngOnInit() {
    this.getPreScannedDevices();
    this.getPatientsWithScannedDevices();
  }


  deleteScannedDevicesForPatient(patient) {
    this.dataService.delete('DeviceScan/DeleteScannedDevicesForPatient', 'PatientUniqueId', patient.PatientUniqueId).subscribe(
      (res: any) => {
        this.patientsWithScannedDevices = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  getPatientsWithScannedDevices() {
    this.dataService.getall('DeviceScan/GetPatientsWithScannedDevices').subscribe(
      (res: any) => {
        this.patientsWithScannedDevices = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  getPreScannedDevices() {
    this.dataService.getall('DeviceScan/GetScannedDeviceGroups').subscribe(
      (res: any) => {
        this.scannedDeviceGroups = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }
  
}


@Component({
  selector: 'dialog-scandevicepatient',
  templateUrl: 'scanDevicePatientDialog.html',
})
export class ScanDevicePatientDialog {

  @ViewChild('dynamicComponent', { read: ViewContainerRef }) container;
  componentRef: ComponentRef<any>
  hostedComponent = null;
  form!: FormGroup;
  hostedComponentSubs: any;
  scannedDevices = [];
  selectedPatient = null;
  selectedRun = null;
  scannedDeviceGroups = [];

  constructor(public dialogRef: MatDialogRef<ScanDevicePatientDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private appService: AppService, private fb: FormBuilder, private dataService: DataService
    , private resolver: ComponentFactoryResolver) {
  }


  ngOnInit() {
    this.form = this.fb.group({});
    console.log(this.data.patient);
    this.scannedDeviceGroups = this.data.scannedDeviceGroups;


    //get all scanned devices for patient
    this.dataService.get('DeviceScan/GetScannedDevicesForPatient', 'PatientUniqueId', this.data.patient.PatientUniqueId).subscribe(
      (res: any) => {
        this.scannedDevices = res.data;
        this.selectedPatient = this.data.patient;
      },
      err => {
        console.log(err);
      }
    );


    if (this.scannedDeviceGroups.length > 0) {
      this.form.addControl('PreScannedDevice', new FormControl(null));
      this.form.addControl('DeviceType', new FormControl(null));
    } else {
      //DeviceType is required when there are no pre scanned devices
      this.form.addControl('DeviceType', new FormControl(null, Validators.required));
    }

    setTimeout(() => {//Timeout is required for container to be defined
      this.createComponent();
    }, 0);

  }

  confirmDialogOption(dialogOption: string): void {
    var dialogResult = {
      strNextOrBack: this.data.strNextOrBack,
      dialogOption: dialogOption,
      data: this.form.value,
    };

    if (dialogOption == 'yes') {
      if (this.scannedDevices.length > 0) {
          this.dataService.create('DeviceScan/CreateScannedDevicesForPatient', this.scannedDevices).subscribe(
            (res: any) => {
              this.dialogRef.close(dialogResult);
            },
            err => {
              console.log(err);
            }
          );

      }
    } else {
      this.dialogRef.close(dialogResult);
    }
  }

  createComponent() {
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.container.createComponent(factory);
    //const hostedComponent = this.componentRef.instance;
    this.hostedComponent = this.componentRef.instance;

    //////////////// Pass through outputs from hosted component//////////////////
    Object.keys(this.hostedComponent)
      .filter(prop => this.hostedComponent[prop] instanceof EventEmitter)
      .forEach(output => {
        this[output] = new EventEmitter()
        this[`${output}_sub`] = this.hostedComponent[output].subscribe(data => {
          this[output].emit(data)
        })
      });
    //used to receive data from child component that is hosted in material dialog/modal. This wont work without code above
    this.dialogRef.afterOpened()
      .subscribe(() => {
        this.hostedComponentSubs = this.dialogRef.componentInstance['barCodeScanned'].subscribe((event) => { this.attachScannedDevice(event); });
      });
    ////////////////

    //unsubscribe everything after dialog is closed
    this.dialogRef.afterClosed().subscribe(() => this.hostedComponentSubs.unsubscribe())
  }

  attachScannedDevice(scannedDevice, preScanned?) {
    if (this.scannedDevices.length > 0) {
      if (this.appService.lodashFindBy(this.scannedDevices, 'DeviceGudidId', scannedDevice.scannedCode)) {
        var confirmed = confirm('Scanned device is already added. Add another device with same code?');
        if (confirmed != true) {
          return;
        }
      }
    }
    this.scannedDevices.push(
      {
        TempId: this.appService.generateUUID(),
        DeviceScanId: 0,
        PatientUniqueId: this.selectedPatient != null ? this.selectedPatient.PatientUniqueId : null,
        BirthDate: this.selectedPatient != null ? this.selectedPatient.Birthdate : null,
        TimeOn: this.selectedRun != null ? this.selectedRun.TimeOn : null,
        TimeOff: this.selectedRun != null ? this.selectedRun.TimeOff : null,
        Sex: this.selectedPatient != null ? this.selectedPatient.Sex : null,
        DeviceScanSetName: this.selectedPatient.deviceScanSetName,
        ScanOption: this.selectedPatient.ScanOption,
        DeviceGudidId: scannedDevice.scannedCode,
        DeviceType: preScanned == true ? scannedDevice.deviceType : this.form.controls['DeviceType'].value,
      }
    );
    console.log(this.scannedDevices);
  }

  deviceTypeSelectionChange(value) {
    if (this.form.controls['DeviceType'].value == null) {
      this.hostedComponent.disabled = true; //disable scanner button
    } else {
      this.hostedComponent.disabled = false; //enable scanner button
    }
  }
  deleteScannedDevice(scannedDevice) {
    if (scannedDevice.TempId) {
      this.appService.lodashRemoveBy(this.scannedDevices, 'TempId', scannedDevice.TempId);
    } else {
      this.appService.lodashRemoveBy(this.scannedDevices, 'DeviceScanId', scannedDevice.DeviceScanId);
    }
  }
  addPreScannedDevice() {
    this.attachScannedDevice({ scannedCode: this.form.controls['PreScannedDevice'].value, deviceType: this.appService.lodashFindBy(this.scannedDeviceGroups, 'DeviceGudidId', this.form.controls['PreScannedDevice'].value).DeviceType }, true);
  }
}

